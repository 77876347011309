import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "container-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "英文名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入英文名称!"
        }, {
          pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
          message: "英文名称格式错误",
          trigger: ["blur", "change"]
        }]
      }],
      expression: "[\n                        'name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入英文名称!',\n                                },\n                                {\n                                    pattern: /^\\S.*\\S$|(^\\S{0,1}\\S$)/,\n                                    message: '英文名称格式错误',\n                                    trigger: ['blur', 'change'],\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      size: "large",
      placeholder: "请输入",
      maxLength: 20
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "中文名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["cnname", {
        rules: [{
          pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
          message: "中文名称格式错误",
          trigger: ["blur", "change"]
        }]
      }],
      expression: "[\n                        'cnname',\n                        {\n                            rules: [\n                                {\n                                    pattern: /^\\S.*\\S$|(^\\S{0,1}\\S$)/,\n                                    message: '中文名称格式错误',\n                                    trigger: ['blur', 'change'],\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      size: "large",
      placeholder: "请输入",
      maxlength: "50"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "品牌logo"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["imgurl", {
        rules: [{
          required: true,
          message: "请上传图片"
        }],
        initialValue: ""
      }],
      expression: "[\n                        'imgurl',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请上传图片',\n                                },\n                            ],\n                            initialValue: '',\n                        },\n                    ]"
    }],
    attrs: {
      listType: "picture-card",
      max: 1,
      memorySize: "200kb"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "搜索词"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["searchterm", {
        rules: [{
          required: true,
          message: "请输入搜索词!"
        }]
      }],
      expression: "[\n                        'searchterm',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入搜索词!',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      size: "large",
      placeholder: "输入多个用逗号隔开",
      maxlength: "50"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "关联类目"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["categoryIds", {
        rules: [{
          required: true,
          message: "请选择关联类目!"
        }]
      }],
      expression: "[\n                        `categoryIds`,\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择关联类目!',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      "min-height": "40px",
      "min-width": "262px"
    },
    attrs: {
      "tree-checkable": "",
      "show-checked-strategy": _vm.SHOW_CHILD,
      "search-placeholder": "请选择",
      "dropdown-style": {
        maxHeight: "400px",
        overflow: "auto"
      },
      replaceFields: _vm.replaceFields,
      "allow-clear": "",
      multiple: "",
      "tree-default-expand-all": "",
      "tree-data": _vm.brandcategoryrefreq,
      filterTreeNode: _vm.fillterFUN
    },
    on: {
      change: _vm.selectChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["desc"],
      expression: "['desc']"
    }],
    attrs: {
      size: "large",
      type: "textarea",
      maxlength: "50"
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1), _c("a-modal", {
    attrs: {
      visible: _vm.preVisible,
      width: "460px",
      centered: true,
      maskClosable: false,
      closable: true,
      destroyOnClose: true,
      footer: null,
      title: "预览"
    },
    on: {
      cancel: function cancel($event) {
        _vm.preVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "preExposure-box"
  }, [_c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.content)
    }
  })])]), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };